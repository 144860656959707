/**
 * Type para Incluir/Editar/Visualizar
 * 
 * @typedef {Object} AdjustmentLevelResponsibilityType
 * @property {?number} id // [v]
 * @property {?boolean} has_adjustment // [v]
 * @property {number} level_feature
 * @property {?Array<number>} company_plant_ids // [v]
 * @property {?number} binder // [v]
 * @property {?number} water // [v]
 * @property {?number} content_mortar // [v]
 * @property {?number} total_air // [v]
 * @property {?Array<AgentType>} agent // [v]
 * @property {?string} observation // [v]
 * @property {?string} created_at // [v]
 * @property {?string} created_by_user_name //[v]
 */
export const AdjustmentLevelResponsibilityType = {};

/**
 * Inicializar AdjustmentLevelResponsibilityType
 *
 * @returns {AdjustmentLevelResponsibilityType}
 */
export const initAdjustmentLevelResponsibilityType = () => ({
  id: null,
  level_feature: null,
  company_plant_ids: null,
  binder: null,
  water: null,
  content_mortar: null,
  total_air: null,
  agent: null,
  observation: null,
  created_at: null,
  created_by_user_name: null
});