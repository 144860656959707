import {
  BaseListFilterType,
  initBaseListFilterType,
} from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} AdjustmentLevelResponsibilityListFilterExtType
 * @property {null|1|2|3} status - 1-Com Ajuste, 2-Sem Ajuste, null-Todos
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & AdjustmentLevelResponsibilityListFilterExtType)} AdjustmentLevelResponsibilityListFilterType
 */
export const AdjustmentLevelResponsibilityListFilterType = {};

/**
 * Inicializar CmcAdjustmentListFilterType
 *
 * @returns {AdjustmentLevelResponsibilityListFilterType}
 */
export const initAdjustmentLevelResponsibilityListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    order_by: [{ column: "adjustment_level_responsibilities.id", is_desc: false }],
    custom_search: {
      columns: [],
      values: [],
    },
    status: null,
  };
};
