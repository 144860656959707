<template>
  <div>
    <AppFixedPageTitle
      title="NÍVEL DE APLICAÇÃO"
      icon="/img/icons/physics-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        />
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions
          :isBackButtonEnabled="true"
        >
          <AppPageHeaderActionsButton
            @click="openAdjustmentLevelResponsibilityCreateModal"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            @click="openLimitSettingCreateModal"
            text="Limite"
            type="primary"
            icon="/img/icons/icons8/ios/resize-vertical_white.png"
          />
          <AppPageHeaderActionsButton
            @click="openAplicationLevelGuidelineCreateModal"
            text="Orientações"
            type="warning"
            icon="/img/icons/icons8/ios/info-squared_white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    />
    <div class="container-fluid">
      <AdjustmentLevelResponsibilityListPageCards
        @onCancelDosageAdjutmentClick="restoreAdjustmentValues"
        @onUpdateDosageAdjustmentClick="updateDosageAdjustmentValues"
        @onEditItemClick="openAdjustmentLevelResponsibilityEditModal"
        @onRemoveItemClick="removeAdjustmentLevelResponsibility"
        @onCompanyPlantsByIdClick="getCompanyPlantsById"
      />
      <AppViewTrigger v-if="!$_adjustment_level_responsibilities_is_listing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!$_adjustment_level_responsibilities_is_listing && !$_adjustment_level_responsibilities_listed.items.length"/>
    </div>
    <AdjustmentLevelResponsibilityCreateModal ref="adjustmentLevelResponsibilityCreateModal" />
    <AdjustmentLevelResponsibilityEditModal ref="adjustmentLevelResponsibilityEditModal" />
    <LimitSettingCreateModal ref="limitSettingCreateModal"/>
    <AplicationLevelGuidelineCreateModal ref="aplicationLevelGuidelineCreateModal"/>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted, Ref } from 'vue';
import { 
  AppFixedPageTitle, 
  AppPageHeader, 
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import adjustmentLevelResponsibilityStore from "../../store/adjustmentLevelResponsibilityStore";
import {
  initAdjustmentLevelResponsibilityListFilterType,
  AdjustmentLevelResponsibilityListFilterType,
  AdjustmentLevelResponsibilityListType,
  AdjustmentLevelResponsibilityUpdateDosageType,
  AdjustmentLevelResponsibilityType
} from '../../types';
import { dialogs, progress } from '../../../../../helpers';
import AdjustmentLevelResponsibilityListPageCards from './AdjustmentLevelResponsibilityListPageCards.vue';
import { AdjustmentLevelResponsibilityFilterStatusEnum } from "../../enums";
import LimitSettingCreateModal from "../limit-setting/LimitSettingCreateModal.vue";
import AplicationLevelGuidelineCreateModal from "../aplication-level-guideline/AplicationLevelGuidelineCreateModal.vue";
import AdjustmentLevelResponsibilityCreateModal from "../create/AdjustmentLevelResponsibilityCreateModal.vue";
import { AdjustmentLevelResponsibilityCreateModalExpose } from '../create/AdjustmentLevelResponsibilityCreateModal.vue';
import AdjustmentLevelResponsibilityEditModal from "../edit/AdjustmentLevelResponsibilityEditModal.vue";
import { AdjustmentLevelResponsibilityEditModalExpose } from '../edit/AdjustmentLevelResponsibilityEditModal.vue';
import { adjustmentLevelResponsibilityCreateHistoryService } from "../../services";
import AdjustmentHistoryActionsEnum from "../../../adjustment-history/enums/AdjustmentHistoryActionsEnum";
import { useAdjustmentLevelResponsabilityHook } from "../../hooks/useAdjustmentLevelResponsabilityHook";
import PuzlEmptyData from "@/components/PuzlEmptyData";
//#endregion

//#region ComponentRefs
/** @type {Ref<AdjustmentLevelResponsibilityCreateModalExpose>} */
const adjustmentLevelResponsibilityCreateModal = ref(null);
/** @type {Ref<AdjustmentLevelResponsibilityEditModalExpose>} */
const adjustmentLevelResponsibilityEditModal = ref(null);
/** @type {Ref<LimitSettingCreateModal>} */
const limitSettingCreateModal = ref(null);
/** @type {Ref<AplicationLevelGuidelineCreateModal>} */
const aplicationLevelGuidelineCreateModal = ref(null);
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {AdjustmentLevelResponsibilityListFilterType} - Filtro principal */
const filter = reactive(initAdjustmentLevelResponsibilityListFilterType());

const listType = ref('cards');

const { agentGroups } = useAdjustmentLevelResponsabilityHook();
//#endregion

//#region Computeds
/** Store Getters */
const $_adjustment_level_responsibilities_listed = computed(() => adjustmentLevelResponsibilityStore.getters.getListed());
const $_adjustment_level_responsibilities_is_listing = computed(() => adjustmentLevelResponsibilityStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: AdjustmentLevelResponsibilityFilterStatusEnum.keys.WITH_ADJUSTMENT,
      name: 'Com Ajuste',
      selected: filter.status === AdjustmentLevelResponsibilityFilterStatusEnum.keys.WITH_ADJUSTMENT
    },
    {
      id: AdjustmentLevelResponsibilityFilterStatusEnum.keys.WITHOUT_ADJUSTMENT,
      name: 'Sem Ajuste',
      selected: filter.status === AdjustmentLevelResponsibilityFilterStatusEnum.keys.WITHOUT_ADJUSTMENT
    },
  ];
});
//#endregion

//#region Methods
/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();
  
  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? adjustmentLevelResponsibilityStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);
  
  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
};

/**
 * Padrão do filtro principal
 * @returns {AdjustmentLevelResponsibilityListFilterType}
 */
function defaultFilter() {
  return {
    ...initAdjustmentLevelResponsibilityListFilterType(),
    status: null
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {  
  prepareFilter();
  adjustmentLevelResponsibilityStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
};

/**
 * @param {TabSelectItemType} item
 */
 function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * @param {AdjustmentLevelResponsibilityListType} adjustment
 */
async function restoreAdjustmentValues(adjustment) {
  progress.start();

  try {
    const oldAdjustment = await adjustmentLevelResponsibilityStore.actions.find(adjustment.id);
    adjustment.binder = oldAdjustment.binder;
    adjustment.water = oldAdjustment.water;
    adjustment.content_mortar = oldAdjustment.content_mortar;
    adjustment.total_air = oldAdjustment.total_air;
    adjustment.agent = oldAdjustment.agent;
    adjustment.observation = oldAdjustment.observation;
    adjustment.has_adjustment = oldAdjustment.has_adjustment;
    adjustment.is_adjusting = false;
  } catch (error) {
    dialogs.notify(
      "danger",
      "Erro ao atualizar ajuste."
    );
  } finally {
    progress.finish();
  }
}

/**
 * 
 * @param {AdjustmentLevelResponsibilityUpdateDosageType} dosages 
 */
async function updateDosageAdjustmentValues(dosages) {
  progress.start();

  try {
    await adjustmentLevelResponsibilityStore.actions.updateDosages(dosages, dosages.id);

    /** @type {AdjustmentLevelResponsibilityType} adjustmentLevelResponsibility */
    const adjustmentLevelResponsibility = await adjustmentLevelResponsibilityStore.actions.find(dosages.id);
    const has_agent = await hasAgent(adjustmentLevelResponsibility.agent);

    adjustmentLevelResponsibilityCreateHistoryService.execute(
      AdjustmentHistoryActionsEnum.keys.ADJUST,
      adjustmentLevelResponsibility,
      has_agent ? agentGroups : null
    );

    dialogs.notify();
  } catch (error) {
    dialogs.notify(
      "danger",
      "Erro ao atualizar ajuste."
    );
  } finally {
    progress.finish();
  }
}

/**
 * 
 * @param {AdjustmentLevelResponsibilityListType} adjustment 
 */
async function getCompanyPlantsById(adjustment) {
  progress.start();

  try {
    await adjustmentLevelResponsibilityStore.actions.getCompanyPlantsById(adjustment);
  } finally {
    progress.finish();
  }
}

/**
 * 
 * @param {number} id 
 */
async function removeAdjustmentLevelResponsibility(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {

    try {
      /** @type {AdjustmentLevelResponsibilityType} adjustmentLevelResponsibility */
      const adjustmentLevelResponsibility = await adjustmentLevelResponsibilityStore.actions.find(id);
      const has_agent = await hasAgent(adjustmentLevelResponsibility.agent);

      await adjustmentLevelResponsibilityStore.actions.remove(id);

      adjustmentLevelResponsibilityCreateHistoryService.execute(
        AdjustmentHistoryActionsEnum.keys.REMOVE,
        adjustmentLevelResponsibility,
        has_agent ? agentGroups : null
      );
    } catch (error) {
      dialogs.notify(
        "danger",
        "Não foi possível remover o ajuste"
      );
    } finally {
      dialogs.notifyRemoved();
    }
  }
}

/**
 * Função que verifica se ajuste possui aditivo
 * 
 * @param {Array<AgentType>} agents
 * @returns {boolean}
 */
function hasAgent(agents) {
  return agents.some(item => item.id !== null);
}

/** 
 * Abrir modal para criar registro
 */
function openAdjustmentLevelResponsibilityCreateModal() {
  adjustmentLevelResponsibilityCreateModal.value.openModal();
}

/**
 * Abrir modal para editar registro
 * @param {number} id
 */
function openAdjustmentLevelResponsibilityEditModal(id) {
  adjustmentLevelResponsibilityEditModal.value.openModal(id);
}

/**
 * Abrir modal para ajuste de limite
 */
function openLimitSettingCreateModal() {
  limitSettingCreateModal.value.handleCreateModal();
}

/**
 * Abrir modal para orientações
 */
function openAplicationLevelGuidelineCreateModal() {
  aplicationLevelGuidelineCreateModal.value.handleCreateModal();
}
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  adjustmentLevelResponsibilityStore.mutations.resetStates();
  adjustmentLevelResponsibilityStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion

</script>

<style scoped>
</style>